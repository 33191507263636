//public
.bg-gray{
  background: #f4f4f4;
}
.bg-graydark{
  background: #5e5e5e;
}
.bg-white{
  background: #fff;
}
.bg-yellow{
  background: #feaf00;
}
.text-yellow{
  color: #ffaf00;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px!important;
}
.mt-40{
  margin-top: 40px!important;
}
.mt-70{
  margin-top: 70px!important;
}
.pt-0{
  padding-top: 40px;
}
.line{
  width: 100%;
  height: 1px;
  background: #45484c;
}
.main-img{
  min-width: 1200px;
  max-width: 1200px;
  margin:auto;
  img{
    width: 100%;
  }
}
.mainfluid-img{
  min-width: 100%;
  max-width: 100%;
  margin:auto;
  img{
    width: 100%;
  }
}
.pt-navbar{
  padding-top: 60px;
}
//qxb---------navbar
.navbar-main{
  background: #090a0a;
  border-radius: 0;
  margin-bottom: 0;
  border-bottom: solid 1px #434343;
  .navbar-brand{
    height:60px;
  }
  .navbar-toggle{
    border-color: #fff;
    margin-top:15px;
    .icon-bar{
      background-color: #fff;
    }
  }
  .nav{
    >li{
      position: static;
      z-index: 10;
      a{
        color: #ececec;
        padding:20px 15px;
        &:focus,&:hover{
          background: transparent;
          color: #feae00;
        }
      }
      &.nav-line-bottom{
        height: 3px;
        width: 120px;
        background: #feae00;
        position: absolute;
        left: 0;
        top: 58px;
        overflow: hidden;
        z-index: -1;
      }
    }
    .open{
      >a{
        background-color: transparent;
        color: #feae00;
        &:hover,&:focus{
          background-color: transparent;
          //border-color: transparent;
          color: #feae00;
        }
      }
    }
    &.navbar-right{
      margin-right: 15px;
    }
  }
  .dropdown-menu{
    width: 100%;
    min-width: 100%;
    background-color: #1b1b1b;
    .dropdown-menu-items{
      padding:20px 40px 40px;
      .clearfix();
      .media{
        color: #e0e0e0;
        overflow: visible;
        h2{
          font-size: 16px;
          font-weight: bold;
          position: relative;
          img{
            position: absolute;
            width: 28px;
            height:28px;
            left: -35px;
            top:-5px;
          }
        }
        ul{
          &:extend(.clearfix all);
          list-style: none;
          padding-left: 0;
          li{
            a{
              color: #e0e0e0;
              padding:10px 15px 0 0;
              display: inline-block;
              &:hover,&:focus{
                color: #feae00;
              }
            }
          }
        }
      }
    }
  }
}
.item-p{
  background: #333333;
  padding: 20px;
  color: #fff;
  text-indent: 48px;
  font-size: 24px;
}
//qxb---------banner
.banner-container{
  margin-top: 61px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  background-attachment: fixed;

  width: 100%;
  min-height: 100%;
  height: auto;
  .swiper-slide{
    img{
      width: 100%;
    }
  }
}
//
.main-wrap{
  .clearfix();
    .item{
      width: 20%;
      text-align: center;
      border-right: solid 1px #dfe2e7;
      float: left;
      &:last-child{
        border-right: none;
      }
        h1{
          font-size: 44px;
          color: #f88f2a;
          font-weight: bold;
        }
        h2{
          font-size: 16px;
          color: #666;
        }
    }
}


//标题
.item-tit{
  width: 80%;
  height: 14px;
  margin: 50px auto 0px auto;
  font-size: 26px;
  color: #444;
  text-align: center;
  border-bottom: solid 1px #ccc;
  span{
    background: #fff;
    padding:0 25px;
  }
}
.item-subtit{
  font-size:16px;
  text-align: center;
  padding-top: 15px;
  .lump{
    background: #333333;
    color: #fff;
    padding:3px 20px;
    font-size:14px;
  }
}

//切换
.nav{
  &.product-tabs{
    .clearfix();
    >li{
      padding: 0;
      margin:0;
      &.active{
        &:after{
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-bottom: 6px dashed;
          border-bottom: 6px solid\9;
          border-bottom-color: #feae00;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          position: absolute;
          bottom:0;
          left: 50%;
        }
        a{
          border-bottom: solid 2px #feae00;
        }
      }
      >a{
        background: transparent;
        padding:0;
        text-align: center;
        &:hover,&:focus{
          background: transparent;
        }
        img{
          display: block;
          width: 70px;
          max-height: 80px;
          margin:auto;
        }
        .product-tabs-name{
          padding: 20px 0;
          color:#666666;
          font-size:20px;
        }
      }
    }
  }
  &.qxb-nav-tabs{
    >li{
      padding:0;
      &.active{
        >a{
          color: #feaf00;
          background: #fff;
          border: solid 1px #ddd;
          border-bottom: none;
        }
        &:after{
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          vertical-align: middle;
          border-top: 6px dashed;
          border-top: 6px solid\9;
          border-top-color: #feae00;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          position: absolute;
          bottom:30px;
          left: 50%;
        }
      }
      >a{
        border-radius: 0;
        text-align: center;
        padding:45px 0;
        font-size: 24px;
        color: #999;
        background: #fafafa;
        border: none;
        &:hover,&:focus{
          //border-bottom-color: #ffffff;
        }
      }
    }
  }
}
.product-tab-content{
  padding:0 15px;
  .product-tab-container{
    .clearfix();
    .product-normal-box,.product-hot-box{
      a{
        text-decoration: none;
        padding:12px 0;
        display: block;
        &.product-normal-item-wrap,&.product-hot-item-wrap{
          .product-normal-item-name,.product-hot-item-name{
            font-size: 16px;
            color: #666666;
            font-weight: bold;
            line-height: 32px;
          }
          .product-normal-item-description,.product-hot-item-description{
            font-size: 14px;
            color: #999999;
          }

        }
        &.product-hot-item-btn{
          display: block;
          width: 96px;
          height: 28px;
          line-height: 28px;
          border: 1px solid #373d41;
          font-size: 12px;
          color: #373d41;
          text-align: center;
          margin-top: 16px;
          margin-bottom: 30px;
          cursor: pointer;
          text-decoration: none;
          transition: all .3s ease-in-out;
          padding:0;
          &:hover{
            color: #feae00;
            border: 1px solid #feae00;
          }
        }
      }
    }
    .product-normal-shop {
      padding: 15px 0;
      overflow: hidden;
      .product-shop-item {
        display: block;
        float: left;
        margin-left: 2%;
        margin-bottom: 10px;
        margin-top: 10px;
        width: 30%;
        a {
          display: block;
          width: 100%;
          padding: 10px 0;
          background-color: #fff;
          border: 1px solid #ced0d1;
          text-align: center;
          color: #666;
        }
      }
    }
    .product-shop-item-more {
      padding-top: 75px;
    }
  }
}


//服务范围
.service-area{
  position: relative;
  .service-bar{
    .clearfix();
    padding: 30px 0;
    background: url("../images/bg2.png") left top repeat-x;
    position: absolute;
    bottom:0;
    width: 100%;
    .right-line{
      border-right:solid 1px #dde4f9;
    }
    .service-itme{
      .service-itme-tit{
        font-size:20px;
        color: #444444;
        line-height: 50px;
      }
      .service-itme-contant{
        color: #444444;
        font-size:14px;
      }
      .service-itme-right-tit{
        font-size: 22px;
        span{
          font-size:26px;
        }
      }
      .service-itme-btn{
        display: block;
        width: 96px;
        height: 28px;
        line-height: 28px;
        border: 1px solid #ce9500;
        font-size: 12px;
        color: #e59d00;
        text-align: center;
        margin-top: 16px;
        cursor: pointer;
        text-decoration: none;
        transition: all .3s ease-in-out;
        background: #fbf6f2;
        padding:0;
        &:hover{
          color: #feae00;
          border: 1px solid #feae00;
        }
}
    }
  }
}
.product-slog{
  background: url("../images/bgcss.jpg") left top repeat-x;
  h2{
    color:#fff;
    text-align: center;
    font-size:30px;
    min-height: 130px;
    line-height: 130px;
    margin:0;
  }
}
.qxb-main-content{
  .qxb-content-item{
    margin-bottom: 50px;
    a{
     .clearfix();
     width: 344px;
     height: 240px;
     display: inline-block;
     text-decoration: none;
     position: relative;
      &.hyjjfa{
        width:480px;
        height: 198px;
        margin-top: 20px;
      }
      .item-appendix{
        padding: 25px 30px;
        text-align: center;
        color: #666666;
        background: #f9f9f9;
        font-size:16px;
      }
     .img-area{

     }
     .box-header{
       background: url("../images/bg3.png") left top repeat-x;
       position: absolute;
       bottom: 0;
       left: 0;
       line-height: 50px;
       color: #fff;
       font-size:20px;
       text-align: center;
       width: 100%;
     }
   }

  }
}
.product-solution{
  .product-solution-item{
    position: relative;
    &:hover{
      .box-header{
        background: url("../images/bg4.png") repeat;
      }
    }
    .img-area{
      img{
        max-height: 404px;
        width: 100%;
      }
    }
    .box-header{
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      top:0;
      left:0;
      padding-top: 120px;
      &:hover {
        text-decoration: none;
      }
      .tit{
        font-size: 24px;
        color: #fff;
        padding: 30px;
      }
    }
  }

}
//页脚
.footer{
  background: #2e3033;
  .qxb-main-know-content{
    .clearfix();
    .qxb-main-know-content-list{
      width: 25%;
      float: left;
      ul{
        li{
          list-style: none;
          padding:5px 0;
          &.tit{
            font-size: 14px;
            padding:25px 0 20px 0;
          }
          a{
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
  }
  .copy{
    padding:20px 0;
    color: #fff;
    text-align: center;
  }
}


//产品中心
.yx-product{
  .yx-product-box{
    .clearfix();
    position: relative;
    padding:50px 0;
    background-size: 100%;
    &.img1{
      background: url("../images/ybbg1.jpg") left center no-repeat #f4f4f4;
      background-size: 100%;
    }
    &.img2{
      background: url("../images/ybbg2.jpg") left center no-repeat #fff;
      background-size: 100%;
    }
    &.img3{
      background: url("../images/ybbg3.jpg") left center no-repeat #f4f4f4;
      background-size: 100%;
    }
    &.img4{
      background: url("../images/ybbg4.jpg") left center no-repeat #fff;
      background-size: 100%;
    }
    &.img5{
      background: url("../images/ybbg5.jpg") left center no-repeat #fff;
      background-size: 100%;
    }
    &.img6{
      background: url("../images/ybbg6.jpg") left center no-repeat #fff;
      background-size: 100%;
    }
    &.img7{
      background: url("../images/ybbg7.jpg") left center no-repeat #fff;
      background-size: 100%;
      min-height: 738px;
    }
    &.img9{
      background: url("../images/ybbg9.jpg") left center no-repeat #f4f4f4;
      background-size: 100%;
    }
    &.img10{
      background: url("../images/ybbg10.jpg") left center no-repeat #fff;
      background-size: 100%;
    }
    &.img11{
      background: url("../images/ybbg11.jpg") left center no-repeat #f4f4f4;
      background-size: 100%;
    }
    .yx-content{
      color: #fff;
      padding-top: 180px;
      h2{
        font-size: 36px;
        span{
          background-color: rgba(0, 0, 0, 0.41);
          padding:20px 15px;
        }
        .tag{
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.41);
          color: #F6DE31;
          width: 100px;
          height: 100px;
          line-height: 56px;
          display: inline-block;
          margin-left: 15px;
          font-size: 32px;
          text-align: center;
        }
      }
      p{
        padding-top: 30px;
        text-indent: 40px;
        line-height:40px;
        font-size: 18px;
      }
    }
    .xls-content{
      padding-top: 120px;
      &.xls-content-jxc {
        padding-top: 80px;
      }
      .item{
        text-align: center;
        .item-icon{
          text-align: center;
        }
        .tit{
          font-size: 24px;
          padding:20px 0;
        }
      }

    }
  }
}

//关于我们
.introduction-box{
  .clearfix();
  padding:50px 30px;
  border:solid 1px #f3f3f3;
  box-shadow: 3px 12px 25px -18px;
  .tit{
    font-size:30px;
    padding-bottom: 30px;
  }
}

.practice-map{
  img{
    width: 100%;
  }
}
.practice-list{
  list-style: none;
  padding:30px 0;
  li{
    font-size: 15px;
    color: #fff;
    padding:5px 0;
    &.tit{
      font-size:21px;
      padding-bottom: 0;
    }
    &.tit-eg{
      padding-top: 0;
      padding-bottom: 0;
      span{
        font-size:21px;
        border-bottom:solid 1px #fff;
        padding-bottom: 5px;
      }
    }

  }
}
.recruit-box{
  padding:50px 0;
  .clearfix();
  .item-subtit{
    text-align: left;
    padding-top: 0;
    span{
      background: #feaf00;
    }

  }
}
.recruit-list{
  h2{
    border-left:2px solid #feaf00;
    padding:0;
    line-height:40px;
    font-size: 24px;
    color: #202020;
    font-weight: bold;
    padding-left:15px;
  }
  ul{

    li{
      font-size:16px;
      color: #202020;
      list-style: none;
      padding:10px 0;
      &.tit{
        color:#202020;
        font-weight: bold;
      }
    }
  }
}
.recruit{
  p{
    font-size: 17px;
    line-height: 30px;
  }
  .tit{
    font-size:30px;
  }
  .item-subtit{
    text-align: left;
    padding-top: 0;
    .lump{
      padding:5px 50px;
      background: #feaf00;
    }
  }
  .slogan{
    font-size: 20px;
    padding:50px 0;
  }
}

.recruit-wrap{
  padding: 0;
  .clearfix();
  list-style: none;
  border-left: solid 1px #6c6c6c;
  border-top: solid 1px #6c6c6c;
  li{
    padding: 65px 0;
    margin:0;
    border-right: solid 1px #6c6c6c;
    border-bottom: solid 1px #6c6c6c;
    a{
      text-decoration: none;
      text-align: center;
      color:#fff;
      img{
        display: block;
        margin: auto;
      }
    }
    .recruit-name{
      font-size: 20px;
      padding:20px 0;
    }
    .recruit-name-eg{
      font-size: 16px;
    }
  }
}
.joint-box{
  padding:40px;
  .clearfix();
  .tit{
    span{
      padding:5px 20px;
      background-color: rgba(0,0,0,.41);
      color: #fff;
      font-size:24px;
    }
    img{
      padding:0 10px;
    }
  }
}
.qxb-form{
  .form-group{
    margin-bottom: 30px;
    .form-control{
      border-radius: 50px;
      background: #bfbfbf;
      color: #fff;
      &.yzm-input{
        width: 50%;
        display: inline-block;

      }
    }
    .yzm-btn{
      width: 40%;
      display: inline-block;
      border-radius: 50px;
      background: #bfbfbf;
      color: #333333;
      text-align: center;
      padding:7px;
      float: right;
      text-decoration: none;
    }

  }
  .btn-tj{
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    border-radius:50px;
    background: #eeeeee;
    color: #333333;
    padding:5px 40px;
  }
}
.joint-right{
  color:#fff;
  .joint-img{
    margin-bottom: 30px;
  }
  .joint-et{
    padding-left: 30px;
    font-size: 20px;
    margin-bottom: 10px;
    span{
      padding-left: 10px;
    }
  }
  .select-list{
    .clearfix();
    select{
      width: 45%;
    }
  }
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color:#666;font-size:16px;}

　　input:-moz-placeholder, textarea:-moz-placeholder{color:#666;font-size:16px;}

　　input::-moz-placeholder, textarea::-moz-placeholder {color:#666;font-size:16px;}

　　input:-ms-input-placeholder, textarea:-ms-input-placeholder {color:#666;font-size:16px;}
.swiper-silder {
  img {
    max-width: 100%;
  }
}
//2018-03-29
.sub-tit{
  font-size: 14px;
  color: #fff;
  padding:10px 0;
}
.qxb-trial-modal{
  .modal-content{
    background: #eeeeee;
  }
}
.text-red {
  color: #da0000;
}
.qxb-form-list{
  .form-group{
    .yzm-btn{
      background: #bebebe;
      padding-top: 3px;
      padding-bottom: 3px;
      border-color: #bebebe;
      margin-left: 15px;
    }
    .control-label{
      text-align: right;
    }
  }
}
.qxb-form-list .qxb-form-group .col-xs-1, .qxb-form-group .col-lg-1, .qxb-form-group .col-lg-10, .qxb-form-group .col-lg-11, .qxb-form-group .col-lg-12, .qxb-form-group .col-lg-2, .qxb-form-group .col-lg-3, .qxb-form-group .col-lg-4, .qxb-form-group .col-lg-5, .qxb-form-group .col-lg-6, .qxb-form-group .col-lg-7, .qxb-form-group .col-lg-8, .qxb-form-group .col-lg-9, .qxb-form-group .col-md-1, .qxb-form-group .col-md-10, .qxb-form-group .col-md-11, .qxb-form-group .col-md-12, .qxb-form-group .col-md-2, .qxb-form-group .col-md-3, .qxb-form-group .col-md-4, .qxb-form-group .col-md-5, .qxb-form-group .col-md-6, .qxb-form-group .col-md-7, .qxb-form-group .col-md-8, .qxb-form-group .col-md-9, .qxb-form-group .col-sm-1, .qxb-form-group .col-sm-10, .qxb-form-group .col-sm-11, .qxb-form-group .col-sm-12, .qxb-form-group .col-sm-2, .qxb-form-group .col-sm-3, .qxb-form-group .col-sm-4, .qxb-form-group .col-sm-5, .qxb-form-group .col-sm-6, .qxb-form-group .col-sm-7, .qxb-form-group .col-sm-8, .qxb-form-group .col-sm-9, .qxb-form-group .col-xs-10, .qxb-form-group .col-xs-11, .qxb-form-group .col-xs-12, .qxb-form-group .col-xs-2, .qxb-form-group .col-xs-3, .qxb-form-group .col-xs-4, .qxb-form-group .col-xs-5, .qxb-form-group .col-xs-6, .qxb-form-group .col-xs-7, .qxb-form-group .col-xs-8, .qxb-form-group .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}